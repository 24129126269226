import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.css']
})
export class LockedComponent implements OnInit {
  lat: number = 46.067861;
  lng: number = 14.544949;
  


  constructor() { }

  ngOnInit() {
  }

}
