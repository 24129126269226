import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charging',
  templateUrl: './charging.component.html',
  styleUrls: ['./charging.component.css']
})
export class ChargingComponent implements OnInit {
  lat: number = 46.067861;
  lng: number = 14.544949;

  constructor() { }

  ngOnInit() {
  }

}
