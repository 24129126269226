import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { VehicleSelectComponent } from './vehicle-select/vehicle-select.component';
import { VehicleTypeComponent } from './vehicle-type/vehicle-type.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { LockChargeComponent } from './lock-charge/lock-charge.component';
import {ChargingComponent} from './charging/charging.component';

import { AgmCoreModule } from '@agm/core';
import { LockedComponent } from './locked/locked.component';
import { environment } from '../environments/environment';
import { NgQrScannerModule } from 'angular2-qrscanner';


// import { NgQrScannerModule } from 'angular2-qrscanner';
// import { QRCodeModule } from 'angularx-qrcode';


const appRoutes: Routes = [
  { path: '', component: VehicleSelectComponent },
  { path: 'vehicletype', component: VehicleTypeComponent},
  { path: 'qrcode', component: QrCodeComponent},
  { path: 'lockcharge', component: LockChargeComponent},
  { path: 'charging', component: ChargingComponent},
  { path: 'locked', component: LockedComponent}
]

@NgModule({
  declarations: [
    AppComponent,
    VehicleSelectComponent,
    VehicleTypeComponent,
    QrCodeComponent,
    LockChargeComponent,
    ChargingComponent,
    LockedComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgQrScannerModule,
    RouterModule.forRoot(appRoutes),
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAP_KEY,
      libraries: ['places', 'drawing', 'geometry']
      })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }